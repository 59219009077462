<template>
  <div v-if="goodsInfo" class="GoodsInfo">
    <div class="info-flex info-one">
      <h3>{{ goodsInfo.skuName }}</h3>
      <h3>￥{{ goodsInfo.price }}</h3>
    </div>
    <div class="info-flex info-two">
      <div class="goods-parameter">{{ goodsInfo.skuTitle }}</div>
      <!-- <div class="old-price"><span>￥12999.00</span></div> -->
    </div>
    <!-- 优惠卷 -->
    <!-- <div class="info-flex coupon">
      <div class="coupon-content">
        <div class="coupon-List">
          <div class="No1">No1</div>
          <div class="List"><span>进口西餐炉具热销榜</span><img src="@/assets/images/icons/arrow-top.png" alt="arrow"> </div>
        </div>
        <div class="coupon-item">
          <span>卷</span>
          <span>满1000减100</span>
        </div>
      </div>
      <div class="more-coupon">
        <span>领取</span>
        <img src="@/assets/images/icons/arrow-top.png" alt="arrow">
      </div>
    </div> -->

    <ul class="goods-content">
      <li v-for="(item, index) in saleAttr" :key="index" class="goods-content-item">
        <span>{{ item.attrName }}</span><span>{{ item.attrValues }}</span>
      </li>
    </ul>

    <!-- <div class="tOther">
      <div class="tOther-left">
        <img src="@/assets/images/icons/arrow-top.png" alt="jinggao">
        <span>炸矿另售</span>
      </div>
      <div class="tOther-right">
        <span>查看详情</span>
        <img src="@/assets/images/icons/arrow-top.png" alt="arrow">
      </div>
    </div> -->

    <div class="change-num">
      <span>数量</span>
      <div class="num-fun">
        <MinusOutlined
          style="font-size: 20px;margin-left: 16px;"
          :style="{color: inputNumber === 1 ? '#A2A2A2' : ''}"
          @click="changeNumberFun('subtract')"
        />
        <a-input v-model:value="inputNumber" class="input-number" type="text" @change="changeInputFun" />
        <PlusOutlined style="font-size: 20px;margin-right: 16px;" @click="changeNumberFun('plus')" />
      </div>
    </div>
    <div style="height: 1px;" />
    <div class="add-car">
      <a-button class="to-add-car" :loading="addCartLoading" @click="addShoppingCartFun">加入购物车</a-button>
      <div class="Collection">
        <img v-if="isCollection === false" src="@/assets/images/icons/Collection.png" alt="没有收藏" @click="CollectionFun(true)">
        <img v-if="isCollection === true" src="@/assets/images/icons/Collection-hot.png" alt="已收藏" @click="CollectionFun(false)">
      </div>
    </div>

    <div class="activity">
      <span>最新活动</span>
      <span><a href="javascript:;">现在购买，最高可享5折优惠，快去抢购吧！</a></span>
    </div>
  </div>
</template>

<script>
import {
  MinusOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'
import { addToCart } from '@/api/car'
import { isCollect, collectSku } from '@/api/goods'
import { mapActions, mapGetters, mapState } from 'vuex'
import Cookies from 'js-cookie'
export default {
  name: 'GoodsInfo',
  components: {
    MinusOutlined,
    PlusOutlined
  },
  props: {
    detailsInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      inputNumber: 1,
      isCollection: false,
      addCartLoading: false
    }
  },
  computed: {
    ...mapGetters({
      getUesr: 'user/getUser'
    }),
    ...mapState({
      userId: state => state.user.id
    }),
    goodsInfo() {
      return this.detailsInfo.info
    },
    saleAttr() {
      return this.detailsInfo.saleAttr
    }
  },
  watch: {
    'detailsInfo.info': {
      handler(val, oval) {
        if (val && this.userId && Cookies.get('MALL_TOKEN')) {
          this.isCollectFun(val.skuId)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      addShoppingCart: 'car/addShoppingCart'
    }),
    addShoppingCartFun() { // 添加购物车
      // await this.getUserInfo()
      this.addCartLoading = true
      const skuId = this.detailsInfo.info.skuId
      const data = {
        skuId,
        num: this.inputNumber
      }
      addToCart(data).then(res => {
        if (res.code === 0) {
          this.$message.success('商品添加成功')
          this.addShoppingCart(res.data)
          setTimeout(() => {
            this.addCartLoading = false
          }, 500)
        } else {
          setTimeout(() => {
            this.addCartLoading = false
          }, 500)
        }
      }).catch(_ => {
        setTimeout(() => {
          this.addCartLoading = false
        }, 500)
      })
    },
    changeInputFun(e) {
      if (e && Number(this.inputNumber)) {
        this.inputNumber = Number(this.inputNumber)
      } else {
        this.inputNumber = 1
      }
    },
    changeNumberFun(type) {
      if (type === 'subtract') {
        if (this.inputNumber === 1) {
          this.inputNumber = 1
        } else {
          this.inputNumber = --this.inputNumber
        }
      } else {
        this.inputNumber = ++this.inputNumber
      }
    },
    CollectionFun(type) {
      if (this.userId) {
        this.isCollection = type
        const data = {
          skuId: this.detailsInfo.info.skuId
        }
        this.collectSkuFun(data)
      } else {
        this.$message.error('您还没有登录')
      }
    },
    collectSkuFun(data) {
      collectSku(data).then(res => {
        // console.log(res)
      })
    },
    isCollectFun(id) {
      const data = {
        skuId: id
      }
      isCollect(data).then(res => {
        this.isCollection = res.data
      })
    }
  }
}
</script>
<style lang='less' scoped>
.GoodsInfo {
  width: 720px;
  min-height: 640px;

  .info-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info-one {

    & > h3 {
      color: #333333;
      font-weight: bold;
      font-size: 36px;
    }
  }

  .info-two {
    margin-top: 16px;
    margin-bottom: 24px;
    .goods-parameter {
      color: #333333;
      font-size: 20px;
      font-weight: 400;
    }
    .old-price {
      font-weight: 400;
      color: #DBDBDB;
      line-height: 30px;
    }
  }
  .coupon {

    margin-bottom: 40px;

    .coupon-content {
      display: flex;
    }

    .coupon-List {
      width: 234px;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      margin-right: 24px;

      .No1 {
        width: 48px;
        height: 32px;
        background: #E23E34;
        border: 1px solid #E23D34;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
      }
      .List {
        width: 184px;
        height: 32px;
        background-color: #FCE5C4;
        border: 1px solid #E23D34;
        color: #ED4534;
        font-weight: 400;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          margin-left: 6px;
        }
      }
    }

    .coupon-item {
      width: 150px;
      height: 32px;
      background: linear-gradient(270deg, #FBC093 0%, #D32E2E 100%);
      border: 1px solid #D43130;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;

      &>span:first-child {
        padding-right: 8px;
        border-right: 1px solid #fff;
      }
      &>span:last-child {
        margin-left: 16px;
      }
    }
    .more-coupon {
      color: #E23E34;
      font-weight: bold;

      & > img {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
        margin-left: 6px;
      }
    }
  }

  .goods-content {
    margin-bottom: 40px;

    .goods-content-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
      & > span:first-child{
        font-weight: 400;
        color: #A2A2A2;
        margin-right: 25px;
      }
      & > span:last-child{
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .tOther {
    width: 400px;
    height: 56px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;

    .tOther-left {
      color: #999999;
      & > img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        margin-left: 16px;
      }
    }
    .tOther-right {
      color: #333333;
      & > img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        margin-right: 24px;
        transform: rotate(90deg)
      }
    }
  }

  .change-num {
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    & > span {
      color: #A2A2A2;
      font-weight: 400;
      margin-right: 53px;
    }
    .num-fun {
      width: 180px;
      height: 56px;
      border: 1px solid #333333;
      border-radius: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .add-car {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;

    .to-add-car {
      width: 180px;
      height: 56px;
      margin-right: 56px;
      background-color: #3B6652;
      border-radius: 32px;
      text-align: center;
      color: #fff;
    }
    .Collection {
      width: 56px;
      height: 56px;
    }
  }

  .activity {
    font-weight: bold;

    & > span:first-child {
      color: #333333;
      margin-right: 16px;
    }
    & > span > a:last-child {
      color: #EE9961;
    }
  }
}
.input-number {
  border: none;
  outline: none;
  box-shadow: none;
  // border-bottom: 1px solid #F3F3F3;
  width: 100px;
  text-align: center;
  font-size: 18px;
  color: #333333;
}
</style>
