<template>
  <div class="goods-relevant">
    <div class="relevant-bar">
      <ul class="bar-list" @click="clickBarFun($event)">
        <li :class="isclickbar === '相关配件' ? 'active-bar' : ''">相关配件</li>
        <li :class="isclickbar === '相关耗材' ? 'active-bar' : ''">相关耗材</li>
        <li :class="isclickbar === '组合推荐' ? 'active-bar' : ''">组合推荐</li>
      </ul>
      <div class="toPeijian"><span><a href="javascript:;">去往配件耗材选购中心</a> </span><RightOutlined /></div>
    </div>

    <div v-if="isclickbar === '相关配件'" class="relevant-banner">
      <div v-if="list1.length">
        <swiper
          :slides-per-view="5"
          :space-between="10"
          :free-mode="true"
          :navigation="{
            'nextEl': '.scroll-btn-right',
            'prevEl': '.scroll-btn-Left',
          }"
          :pagination="{
            'clickable': true,
            'bulletClass' : 'my-bullet',//需设置.my-bullet样式
            'bulletActiveClass': 'my-bullet-active',
          }"
          class="mySwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(item) in list1" :key="item.id">
            <GoodsCard :goods-info="item" />
          </swiper-slide>
        </swiper>
        <div v-show="swiperIndex != 0" class="scroll-btn-Left">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-left">
        </div>
        <div v-show="swiperIndex != 2" class="scroll-btn-right">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-right">
        </div>
      </div>
      <div v-if="!list1.length" style="height: 400px;padding-top: 100px;">
        <a-empty :description="'暂无数据'" />
      </div>
    </div>

    <div v-if="isclickbar === '相关耗材'" class="relevant-banner">
      <div v-if="list2.length">
        <swiper
          :slides-per-view="5"
          :space-between="10"
          :free-mode="true"
          :navigation="{
            'nextEl': '.scroll-btn-right',
            'prevEl': '.scroll-btn-Left',
          }"
          :pagination="{
            'clickable': true,
            'bulletClass' : 'my-bullet',//需设置.my-bullet样式
            'bulletActiveClass': 'my-bullet-active',
          }"
          class="mySwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(item) in list2" :key="item.id">
            <GoodsCard :goods-info="item" />
          </swiper-slide>
        </swiper>
        <div v-show="swiperIndex != 0" class="scroll-btn-Left">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-left">
        </div>
        <div v-show="swiperIndex != 2" class="scroll-btn-right">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-right">
        </div>
      </div>
      <div v-if="!list2.length" style="height: 400px;padding-top: 100px;">
        <a-empty :description="'暂无数据'" />
      </div>

    </div>

    <div v-if="isclickbar === '组合推荐'" class="relevant-banner">
      <div v-if="list3.length">
        <swiper
          :slides-per-view="5"
          :space-between="10"
          :free-mode="true"
          :navigation="{
            'nextEl': '.scroll-btn-right',
            'prevEl': '.scroll-btn-Left',
          }"
          :pagination="{
            'clickable': true,
            'bulletClass' : 'my-bullet',//需设置.my-bullet样式
            'bulletActiveClass': 'my-bullet-active',
          }"
          class="mySwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(item, index) in list3" :key="index">
            <GoodsCard :goods-info="item" />
          </swiper-slide>
        </swiper>
        <div v-show="swiperIndex != 0" class="scroll-btn-Left">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-left">
        </div>
        <div v-show="swiperIndex != 2" class="scroll-btn-right">
          <img src="@/assets/images/icons/arrow-right-shadow.png" alt="arrow-right">
        </div>
      </div>
      <div v-if="!list3.length" style="height: 400px;padding-top: 100px;">
        <a-empty :description="'暂无数据'" />
      </div>

    </div>

  </div>
</template>

<script>
import 'swiper/swiper.less'
import 'swiper/components/pagination/pagination.min.css'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import { ref, reactive } from 'vue'
import { getAboutSku } from '@/api/goods'
import {
  RightOutlined
} from '@ant-design/icons-vue'
import GoodsCard from '@/components/goodsCard'
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay
} from 'swiper/core'

SwiperCore.use([Pagination, Navigation, Autoplay])

export default {
  name: 'GoodsRelevant',
  components: {
    RightOutlined,
    Swiper,
    SwiperSlide,
    GoodsCard
  },
  props: {
    skuId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      swiperIndex: 0,
      isclickbar: '相关配件',
      relevantList: [],
      type: 1,
      list1: [],
      list2: [],
      list3: []
    }
  },
  watch: {
    'skuId': {
      handler(val, oval) {
        if (val) {
          this.getAboutSkuFun()
        }
      }
    }
  },
  created() {
    this.getAboutSkuFun()
  },
  methods: {
    clickBarFun(e) {
      if (e.target.innerHTML === '相关配件') {
        this.isclickbar = '相关配件'
        this.type = 1
      } else if (e.target.innerHTML === '相关耗材') {
        this.isclickbar = '相关耗材'
        this.type = 2
      } else {
        this.type = 3
        this.isclickbar = '组合推荐'
      }
      this.getAboutSkuFun()
    },
    onSlideChange(e) {
      this.swiperIndex = e.activeIndex
    },
    getAboutSkuFun() {
      const data = {
        skuId: this.skuId,
        type: this.type
      }
      getAboutSku(data).then(res => {
        if (this.type === 1) {
          this.list1 = res.skuSku || []
        }
        if (this.type === 2) {
          this.list2 = res.skuSku || []
        }
        if (this.type === 3) {
          this.list3 = res.skuSku || []
        }
      })
    }
  }

}

</script>
<style lang='less' scoped>
.goods-relevant {
  .relevant-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

  .bar-list {
    display: flex;
    align-items: center;

    & > li {
      width: 160px;
      height: 48px;
      // background-color: #3B6652;
      border-radius: 24px;
      font-weight: bold;
      font-size: 20px;
      transition: all .2s linear;
      cursor: pointer;
      text-align: center;
      line-height: 48px;
      margin-right: 24px;

      &:hover {
        color: #3B6652;
      }
    }
    .active-bar {
      background-color: #3B6652;
      color: #fff;
    }
  }
  .toPeijian {
    font-weight: 400;
    color: #999999;

    &>span {
      margin-right: 8px;
    }
  }
}
.relevant-banner{
    position: relative;
    margin-bottom: 32px;

    .relevant-item {
      position: relative;

    }

  }
  .swiper-container {
    padding-bottom: 64px;
  }
  .swiper-wrapper {

    .swiper-pagination {
      text-align: right !important;

    }
  }
// 轮播前进后退按钮
.scroll-btn-Left {
    position: absolute;
    top: -70px;
    bottom: 0;
    left: -80px;
    width: 80px;
    height: 80px;
    transform: rotate(180deg);
    cursor: pointer;
    margin: auto;
  }
  .scroll-btn-right {
    position: absolute;
    top: -70px;
    bottom: 0;
    right: -80px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin: auto;
  }
}

</style>
