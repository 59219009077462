<template>
  <div class="Goodsbanner">
    <swiper
      :slides-per-view="1"
      :pagination="true"
      class="goodsSwiper"
      @slideChange="onSlideChange"
    >
      <template v-for="(item) in imagesList" :key="item.id">
        <swiper-slide>
          <div class="Goodsbanner-item">
            <img v-lazy="item.imgUrl" :alt="item.id">
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import 'swiper/swiper.less'
import 'swiper/components/pagination/pagination.min.css'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay
} from 'swiper/core'
SwiperCore.use([Pagination, Navigation, Autoplay])
export default {
  name: 'Goodsbanner',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    imgList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const swiperIndex = ref(0)
    const onSlideChange = function(e) {
      swiperIndex.value = e.activeIndex
    }
    const imagesList = computed(() => props.imgList)
    return {
      onSlideChange,
      swiperIndex,
      imagesList
    }
  }
}
</script>
<style lang='less'>
.Goodsbanner {
  width: 560px;
  height: 640px;

  .Goodsbanner-item {
    width: 560px;
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
.goodsSwiper {

  .swiper-pagination {
    text-align: center !important;
  }
}
</style>
