<template>
  <div class="container">
    <Breadcrumb :route-list="routeInfo" />
    <div class="details">
      <div class="goods">
        <div class="goods-banner">
          <Goodsbanner :img-list="goodsDetailsInfo.images" />
        </div>
        <div class="goods-info">
          <GoodsInfo :details-info="goodsDetailsInfo" />
        </div>
      </div>
      <GoodsRelevant :sku-id="skuId" />
      <parameter />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Goodsbanner from '@/views/shop/components/goodsbanner'
import GoodsInfo from '@/views/shop/components/goodsInfo'
import GoodsRelevant from '@/views/shop/components/goodsRelevant'
import Parameter from '@/views/shop/details/component/parameter.vue'
import { getCateLevelCategory, getNextCategory, skuinfoDetail, saveFooterPrint } from '@/api/goods'
import { mapState } from 'vuex'

export default {
  name: 'Details',
  components: {
    Breadcrumb,
    Goodsbanner,
    GoodsInfo,
    GoodsRelevant,
    Parameter
  },
  provide() {
    return {
      info: this.getInfo,
      groupAttrs: this.getGroupAttrs,
      desp: this.getDesp
    }
  },
  data() {
    return {
      routeInfo: [
        { path: '/shop/allgoods', title: '全部商品' },
        { path: '/shop/details', title: '商品详情' }
      ],
      goodsDetailsInfo: {},
      skuId: '',
      groupAttrs: [],
      setTime: null
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.id
    })
  },
  watch: {
    '$route.query': {
      handler(val, oval) {
        // console.log(val, oval)
        if (val.catalog2Id) {
          this.getCateLevelCategoryFun(val.catalog2Id)
        }
        if (val.skuId) {
          this.getGoodsDetails(val.skuId)
          this.skuId = val.skuId
        }
        if (this.userId) {
          const _this = this
          this.setTime = setTimeout(_ => {
            _this.saveFooterPrintFun()
          }, 3000)
        }
      },
      deep: true
    }
  },
  created() {
    // this.$route.query
    if (this.$route.query.catalog2Id) {
      this.getCateLevelCategoryFun(this.$route.query.catalog2Id)
    }
    if (this.$route.query.skuId) {
      this.getGoodsDetails(this.$route.query.skuId)
      this.skuId = this.$route.query.skuId
    }
    if (this.userId) {
      const _this = this
      this.setTime = setTimeout(_ => {
        _this.saveFooterPrintFun()
      }, 3000)
    }
  },
  unmounted() {
    if (this.setTime) clearTimeout(this.setTime)
  },
  methods: {
    getGroupAttrs() { // 给所有子组件传递数据
      return this.goodsDetailsInfo.groupAttrs
    },
    getDesp() { // 给所有子组件传递数据
      return this.goodsDetailsInfo.desp
    },
    getInfo() {
      return this.goodsDetailsInfo.info
    },
    getGoodsDetails(skuId) {
      skuinfoDetail(skuId).then(res => {
        const data = res.data
        // 暂时 将默认图片放在轮播
        if (!data.images.length) data.images.push({ id: data.info.skuId, imgUrl: data.info.skuDefaultImg })
        this.goodsDetailsInfo = data
      })
    },
    getNextCategoryFun(catId) {
      getNextCategory(catId).then(res => {
      })
    },
    getCateLevelCategoryFun(catalog2Id) {
      getCateLevelCategory().then(res => {
        const list = res.data.filter(item => item.catId === Number(catalog2Id))
        // this.getNextCategoryFun(list[0].catId)
        this.routeInfo[1].title = list[0].name
        this.routeInfo[1].path = `/shop/allGoods?catId=${list[0].catId}`
      })
    },
    saveFooterPrintFun() {
      const data = {
        skuId: this.goodsDetailsInfo.info.skuId
      }
      saveFooterPrint(data).then(res => {
        // console.log(res)
      })
    }
  }
}
</script>
<style lang='less' scoped>
.details {
  margin-top: 32px;

  .goods {
    display: flex;
    padding-bottom: 64px;
    border-bottom: 1px solid #F3F3F3;
    margin-bottom: 32px;
    justify-content: space-between;
  }

}
</style>
