<template>
  <div class="parameter">
    <div class="relevant-bar">
      <ul class="bar-list" @click="clickBarFun">
        <li :class="isclickbar === '商品详情' ? 'active-bar' : ''">商品详情</li>
        <li :class="isclickbar === '售后保障' ? 'active-bar' : ''">售后保障</li>
        <li :class="isclickbar === '相关资料' ? 'active-bar' : ''">相关资料</li>
        <!-- <li :class="isclickbar === '商品评价' ? 'active-bar' : ''">商品评价</li> -->
      </ul>
    </div>
    <div>
      <keep-alive>
        <component :is="parameterName" :info="componentsInfo" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import parameterDetail from '@/views/shop/details/component/parameterDetail.vue'
import parameterInfo from '@/views/shop/details/component/parameterInfo.vue'
import parameterService from '@/views/shop/details/component/parameterService.vue'
import { getSalesBySpuId } from '@/api/goods'
export default {
  name: 'Parameter',
  components: {
    parameterDetail,
    parameterInfo,
    parameterService
  },
  inject: ['info'],
  data() {
    return {
      isclickbar: '商品详情',
      parameterName: 'parameterDetail',
      componentsInfo: []
    }
  },
  computed: {
    detailsInfo() {
      return this.info
    }
  },
  methods: {
    clickBarFun(e) {
      switch (e.target.innerHTML) {
        case '商品详情'
          :this.isclickbar = '商品详情'
          this.parameterName = 'parameterDetail'
          break
        case '售后保障'
          :this.isclickbar = '售后保障'
          this.parameterName = 'parameterService'
          this.getSalesBySpuIdFun(1)
          break
        case '相关资料'
          :this.isclickbar = '相关资料'
          this.parameterName = 'parameterInfo'
          this.getSalesBySpuIdFun(2)
          break
        case '商品评价'
          :this.isclickbar = '商品评价'
          break
      }
    },
    getSalesBySpuIdFun(type = 1) {
      const data = {
        spuId: this.detailsInfo().spuId,
        isSales: type
      }
      this.componentsInfo = []
      getSalesBySpuId(data).then(res => {
        this.componentsInfo = res.salesSpu || []
      })
    }
  }
}
</script>
<style lang='less' scoped>
.parameter {
  padding-bottom: 32px;
  .relevant-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 68px;

    .bar-list {
      display: flex;
      align-items: center;

      & > li {
        width: 160px;
        height: 48px;
        // background-color: #3B6652;
        border-radius: 24px;
        font-weight: bold;
        font-size: 20px;
        transition: all .2s linear;
        cursor: pointer;
        text-align: center;
        line-height: 48px;
        margin-right: 24px;
        &:hover {
          color: #3B6652;
          // color: #fff;
        }
      }
      .active-bar {
        background-color: #3B6652;
        color: #fff;
      }
    }
  }
}
</style>
