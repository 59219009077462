<template>
  <div class="ParameterInfo">
    <div v-if="list.length">
      <div v-for="(item, index) in list" :key="index" class="parameter-item">
        <div class="parameter-title">
          <div class="circular" />
          <span>{{ item.title }}</span>
        </div>
        <template v-if="item.type === 0">
          <div class="parameter-content">
            <a-row class="db-mb16">
              <a-col :span="4">
                <span class="content-name">序号</span>
                <span>{{ fileIndex + 1 }}</span>
              </a-col>
              <a-col :span="12">
                <div>
                  <span class="content-name">文件名称</span>
                  <span style="color: #3B6652;">{{ item.memo }}</span>
                </div>
              </a-col>
              <!-- <a-col :span="4">
                <span class="content-name">序号</span>
                <span>- 配件供货服务</span>
              </a-col> -->
            </a-row>
          </div>
        </template>
        <template v-if="item.type === 1">
          <div v-for="(fileItme, fileIndex) in item.fileList" :key="fileIndex" class="parameter-content">
            <a-row class="db-mb16">
              <a-col :span="4">
                <span class="content-name">序号</span>
                <span>{{ fileIndex + 1 }}</span>
              </a-col>
              <a-col :span="12">
                <div>
                  <span class="content-name">文件名称</span>
                  <a style="color: #3B6652;" :href="fileItme.url">{{ fileItme.name }}</a>
                </div>
              </a-col>
              <!-- <a-col :span="4">
                <span class="content-name">序号</span>
                <span>- 配件供货服务</span>
              </a-col> -->
            </a-row>
          </div>
        </template>
        <template v-if="item.type === 2">
          <div v-for="(fileItme, fileIndex) in item.fileList" :key="fileIndex" class="parameter-content">
            <a-row class="db-mb16">
              <a-col :span="16">
                <div>
                  <!-- <div class="parameter-title">
                    <div class="circular" />
                    <span>相关视频</span>
                  </div> -->
                  <div class="parameter-content">
                    <video class="video-box" controls :src="fileItme.url" />
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </template>
      </div>
      <!-- <div class="parameter-item">
        <div class="parameter-title">
          <div class="circular" />
          <span>相关视频</span>
        </div>
        <div class="parameter-content">
          <div class="video-box">
            video-box
          </div>
        </div>
      </div> -->

      <!-- <div class="parameter-item">
        <div class="parameter-title">
          <div class="circular" />
          <span>其他说明</span>
        </div>
        <div class="parameter-content">
          <a-row class="db-mb16">
            <a-col :span="24">
              <span>此处显示产品相关的临时性说明</span>
            </a-col>
          </a-row>
        </div>
      </div> -->
    </div>
    <div v-if="!list.length" style="height: 400px;padding-top: 100px;">
      <a-empty :description="'暂无数据'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParameterInfo',
  props: {
    info: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    'info': {
      handler(val, oval) {
        if (val) {
          const data = val
          data.forEach(ele => {
            ele.fileList = ele.memo.split('&&&').map(item => {
              return { url: item, name: ele.desp }
            })
          })
          this.list = data
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
.ParameterInfo {

  .parameter-title {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;

    .circular {
      width: 8px;
      height: 8px;
      background-color: @bg-color;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .parameter-item {
    border-bottom: 1px solid #f3f3f3;
    padding: 32px 0;

    &:last-child {
      border-bottom: none;
    }

    .parameter-content {
      font-size: 400;
      color: #666666;

      .content-name {
        color: #A2A2A2;
        display: block;
        margin-bottom: 9px;
      }

      .video-box {
        width: 440px;
        height: 330px;
        // background: #3B6652;
      }

    }

  }
}
</style>
