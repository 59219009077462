<template>
  <div class="parameterDetail">
    <div v-for="(item, index) in list" :key="index" class="parameter-item">
      <div class="parameter-title">
        <div class="circular" />
        <span>{{ item.groupName }}</span>
      </div>
      <div class="parameter-content">
        <a-row>
          <a-col v-for="(citem, cindex) in item.attrs" :key="cindex" :span="8" class="db-mb16">
            <span class="content-name">{{ citem.attrName }}</span>
            <span>{{ citem.attrValue }}</span>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="parameter-item">
      <div class="parameter-title">
        <div class="circular" />
        <span>其它描述</span>
      </div>
      <div class="parameter-content">
        <img v-lazy="description.decript || ''" alt="">
        <!-- <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 使用电压必须与本设备使用电压相符合，箱体后部下方设有铜质等电位接线端子，应用不小于1.5mm²铜芯导线按规定可靠连接；
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 本产品需由受过训练的厨师操作，不可以拆卸，不可以改装。产品额拆卸、改装会导致严重事故发生；
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 清洁前要拔掉插头，切断电源，清洁时，不得使用喷射水流清洗；水会导电，产品有可能因漏电而引起触电事故发生；
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 应使用新植物油（或起酥油），不应使用旧油，以免降低闪电及过度沸腾；
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 工作完毕后，请按逆时针方向旋转温控器到限定位置，同时切断电源，确保安全；
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span class="content-name">
              - 为使炸炉安全耐用，用户应定期清除油缸中 残渣及电热管上的污物，以保持油缸及电热管长期处理清洁状态；
            </span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="content-name">
              - 油缸内的隔油网是为保护电热管而安设，在炸制食品时，该隔油网必须放在油缸内
            </span>
          </a-col>
        </a-row> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParameterDetail',
  components: {
  },
  inject: ['groupAttrs', 'desp'],
  data() {
    return {
      // list: []
    }
  },
  computed: {
    list() {
      return this.groupAttrs()
    },
    description() {
      return this.desp() || { decript: '' }
    }
  }
}
</script>
<style lang='less' scoped>
.parameterDetail {

  .parameter-title {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;

    .circular {
      width: 8px;
      height: 8px;
      background-color: @bg-color;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .parameter-item {
    border-bottom: 1px solid #f3f3f3;
    padding: 32px 0;

    &:last-child {
      border-bottom: none;
    }

    .parameter-content {
      font-size: 400;
      color: #666666;

      .content-name {
        color: #A2A2A2;
        margin-right: 24px;
      }

    }

  }

}
</style>
