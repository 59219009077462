<template>
  <div class="parameterService">
    <div v-if="list.length">
      <div v-for="(item, index) in list" :key="index" class="parameter-item">
        <div class="parameter-title">
          <div class="circular" />
          <span>{{ item.title }}</span>
        </div>
        <div class="parameter-content">
          <a-row class="db-mb16">
            <a-col :span="24">
              <span>{{ item.memo }}</span>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div v-if="!list.length" style="height: 400px;padding-top: 100px;">
      <a-empty :description="'暂无数据'" />
    </div>

    <!-- <div class="parameter-item">
      <div class="parameter-title">
        <div class="circular" />
        <span>厨窗保障</span>
      </div>
      <div class="parameter-content">
        <a-row class="db-mb16">
          <a-col :span="24">
            <span>- 人工维修服务</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span>- 配件供货服务</span>
          </a-col>
        </a-row>
      </div>
    </div> -->

    <!-- <div class="parameter-item">
      <div class="parameter-title">
        <div class="circular" />
        <span>退换承诺</span>
      </div>
      <div class="parameter-content">
        <a-row class="db-mb16">
          <a-col :span="24">
            <span>客户购买厨窗商品7日内（含7日，自客户收到商品之日起计算），在保证商品完好的前提下，可无理由退货，
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span>
              同时厨窗将按照您购物时的支付方式退款。厨窗提供有偿上门收货服务或自行邮寄退货商品至厨窗指定地点
            </span>
          </a-col>
        </a-row>
        <a-row class="db-mb16">
          <a-col :span="24">
            <span>
              （折扣商品，定制商品、以及被切割、裁剪和油漆过的商品， 不适用于厨窗退货政策，详情请见各商品细则）</span>
          </a-col>
        </a-row>
      </div>
    </div> -->

    <!-- <div class="parameter-item">
      <div class="parameter-title">
        <div class="circular" />
        <span>厨窗说明</span>
      </div>
      <div class="parameter-content">
        <a-row class="db-mb16">
          <a-col :span="24">
            <span>厨窗向您保证所售商品均为正品行货，因厂家会在没有任何提前通知的情况下更改产品包装、产地或者一些附件，</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span> 厨窗不能确保您收到的货物与商城图片、产地、附件说明完全一致。若厨窗没有及时更新，请您谅解并可随时向厨窗客服反馈！</span>
          </a-col>
        </a-row>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ParameterService',
  props: {
    info: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    list() {
      // this.info.forEach(item => {
      //   console.log(item.memo)
      // })
      return this.info
    }
  }
}
</script>
<style lang='less' scoped>
.parameterService {

  .parameter-title {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;

    .circular {
      width: 8px;
      height: 8px;
      background-color: @bg-color;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .parameter-item {
    border-bottom: 1px solid #f3f3f3;
    padding: 32px 0;

    &:last-child {
      border-bottom: none;
    }

    .parameter-content {
      font-size: 400;
      color: #666666;

      .content-name {
        color: #A2A2A2;
        margin-right: 24px;
      }

    }

  }
}
</style>
